import { start } from 'single-spa'

import envConfig from './envConfig'
import { useCrumbs } from './features/App/Hooks/Breadcrumbs'
import ccApis from './helpers/ccApis'
import ccLocalization from './helpers/ccLocalization'
import ccSelectors from './helpers/ccSelectors'
import PermissionsRoute from './helpers/permissions/PermissionsRoute'
import Locations from './helpers/tools/locations'
import ccActions from './helpers/ccActions'
import { store, history } from './redux/store'
import ApiBase from './api/ApiBase'
import ErrorBoundary from './features/App/components/ErrorBoundary'

export function domElementGetter () {
  return document.getElementById('micro-apps-content')
}

export const init = () => {
  SystemRegister('single-spa', () => require('single-spa'))
  SystemRegister('react-router', () => require('react-router'))
  SystemRegister('react-router-dom', () => require('react-router-dom'))
  SystemRegister('connected-react-router', () => require('connected-react-router'))
  SystemRegister('redux', () => require('redux'))
  SystemRegister('react-redux', () => require('react-redux'))
  SystemRegister('react', () => require('react'))
  SystemRegister('react-dom', () => require('react-dom'))
  SystemRegister('react-dom/server', () => require('react-dom/server'))
  SystemRegister('redux-saga', () => require('redux-saga'))
  SystemRegister('redux-saga/effects', () => require('redux-saga/effects'))
  SystemRegister('currency-codes', () => require('currency-codes'))
  SystemRegister('generic-ui-components', () => require('@zooz/generic-ui-components'))
  SystemRegister('@zooz/generic-ui-components', () => require('@zooz/generic-ui-components'))
  SystemRegister('@zooz/react-localize', () => require('@zooz/react-localize'))
  SystemRegister('axios', () => require('axios'))
  SystemRegister('demo-config', () => envConfig.demo)
  SystemRegister('app-config', () => ({ domElementGetter }))
  SystemRegister('cc-locations', () => Locations)
  SystemRegister('cc-permissions-utils', () => require('./helpers/permissions/PermissionsUtil'))
  SystemRegister('cc-feature-toggle-utils', () => require('./helpers/permissions/FeaturesToggleUtil'))
  SystemRegister('cc-permissions-wrapper', () => require('./helpers/permissions/PermissionsWrapper'))
  SystemRegister('cc-bu-util', () => require('./helpers/businessUnitsHelper'))
  SystemRegister('cc-sentry', () => require('./helpers/sentry'))
  SystemRegister('cc-provider-logo', () => require('./helpers/tools/ProviderLogo'))
  SystemRegister('cc-local-storage', () => require('./helpers/localStorageAccessor/localStorageAccessor'))
  SystemRegister('store', () => store)
  SystemRegister('API_URL', () => envConfig.apiUrl)
  SystemRegister('cc-history', () => history)
  SystemRegister('cc-session', () => require('./helpers/ccSession'))
  SystemRegister('cc-context-switcher', () => require('./features/App/helpers/contextSwitchRedirect'))
  SystemRegister('cc-selectors', () => ccSelectors)
  SystemRegister('cc-components', () => ({ PermissionsRoute, ErrorBoundary }))
  SystemRegister('cc-actions', () => ccActions)
  SystemRegister('cc-hooks', () => ({ useCrumbs }))
  SystemRegister('cc-localization', () => ccLocalization)
  SystemRegister('cc-apis', () => ccApis)
  SystemRegister('cc-api-base', () => ({ ApiBase }))

  function SystemRegister (name, requirer) {
    System.register(name, [], _export => ({
      execute: () => {
        _export(requirer())
        // deliberate undefined return because returning something here screws up systemjs
        // return void (0)
      }
    }))
  }
}

export const startMicroApps = () => {
  start()
}
