import envConfig from '../envConfig'
import { getAppUrl, getVersionUrl } from '../helpers/microApps/buildUrls'
import { combinePathsWithEnv } from '../helpers/routeUtil'
import Locations from '../helpers/tools/locations'
import { MicroApp } from '../types/microApp'
import FeaturesToggleUtil from '../helpers/permissions/FeaturesToggleUtil'
import PermissionsUtil from '../helpers/permissions/PermissionsUtil'

const microAppUrl = envConfig.apps.paymentsInsights

const App: MicroApp = {
  name: 'payments_insights',
  importFunction: async (version: string) => (
    System.import(getAppUrl(microAppUrl, version))
  ),
  getVersion: async (): Promise<string> => {
    const version = await System.import(getVersionUrl(microAppUrl))
    return version.default
  },
  permissionsCheck: (): boolean => {
    const hasPermission = PermissionsUtil.isAllowed(PermissionsUtil.any([
      PermissionsUtil.permissions.paymentsIntelligence.insights.getTransactions(),
      PermissionsUtil.permissions.paymentsIntelligence.insights.getBenchmarks()
    ]))
    const hasFeature = FeaturesToggleUtil.isAccountOn('payments_insights')
    return hasPermission && hasFeature
  },
  paths: combinePathsWithEnv([
    Locations.paymentsInsights
  ])
}

export default App
